import React from 'react'
import styled from 'styled-components'

// Hooks
import useSetBackgroundColor from '@hooks/useSetBackgroundColor'
import useIsTablet from '@hooks/useIsTablet'

// Components
import SectionComponent from '@components/SectionComponent'
import Grid from '@components/ViewGridComponent'
import { ImageComponent } from '@components/ImageComponent'
import Spacer from '@components/SpacerComponent'
import { Title1, Paragraph, Caption } from '@components/Typography'
import { Clock } from '@components/IconComponent'

export type Heading = {
  title: string
  copy: string
  readingTime?: number
}

export type Image = {
  src: string
  alt: string
  srcset?: string
  sizes?: string
}

export type FullImageHeroComponentProps = {
  image: Image
  heading: Heading
  content?: React.ReactNode
  contentCenterAlign?: true
  removePadding?: boolean
  reduceHeroHeight?: boolean
  removeSectionMinHeight?: boolean
}

const FullImageHeroComponent: React.FC<FullImageHeroComponentProps> = props => {
  const { ref } = useSetBackgroundColor()
  const isTablet = useIsTablet()

  const contentOrder = isTablet ? 2 : 1
  const headingOrder = isTablet ? 1 : 2
  const contentColumns = isTablet ? '1 / 13' : '1 / 5'
  let headingColumns = isTablet ? '1 / 13' : '6 / 13'

  // If there is no content then hide that section
  if (!props.content) {
    headingColumns = isTablet ? '1 / 13' : '3 / 10'
  }

  return (
    <SectionComponent
      ref={ref}
      removePadding={props.removePadding}
      removeMinHeight={props.removeSectionMinHeight}
    >
      <Grid.Row>
        <Grid.Item column={headingColumns} order={headingOrder}>
          <Spacer top={isTablet ? 'base' : 'lg'} bottom={isTablet ? '0' : 'xl'}>
            <Title1>{props.heading.title}</Title1>
            <Spacer top='lg' />
            <Paragraph>{props.heading.copy}</Paragraph>
            {props.heading.readingTime ? (
              <>
                <Spacer top='lg' />
                <ReadingTime>
                  <Clock />
                  {props.heading.readingTime} min read
                </ReadingTime>
              </>
            ) : null}
          </Spacer>
        </Grid.Item>
        <Grid.Item
          column={contentColumns}
          alignSelfCenter={props.contentCenterAlign}
          order={contentOrder}
        >
          {props.content ? props.content : null}
        </Grid.Item>
      </Grid.Row>
      {/* Hero Image */}
      <Grid.Row>
        <Grid.Item column='1/13'>
          <Spacer bottom='xl' top='base'>
            <HeroImage
              reduceHeight={
                props.reduceHeroHeight === undefined
                  ? true
                  : props.reduceHeroHeight
              }
              {...props.image}
            />
          </Spacer>
        </Grid.Item>
      </Grid.Row>
    </SectionComponent>
  )
}

export default FullImageHeroComponent

const HeroImage = styled(ImageComponent)<{ reduceHeight: boolean }>`
  width: 100%;
  height: ${({ reduceHeight }) => (reduceHeight ? '50vh' : 'auto')};
  object-fit: cover;
  max-height: 640px;
`
const ReadingTime = styled(Caption)`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  align-items: center;
`
