import { graphql } from 'gatsby'
import React from 'react'
import styled, { ThemeProvider } from 'styled-components/macro'

// Components
import FullImageHeroComponent from '@components/FullImageHeroComponent'
import InvertingImageSection from '@components/InvertingImageComponent/InvertingImageComponent'
import { ContentComponent } from '@components/MdxComponents'
import SectionComponent from '@components/SectionComponent'
import SeoComponent from '@components/SeoComponent/SeoComponent'
import Spacer from '@components/SpacerComponent'
import SquaredLinkComponent from '@components/SquaredLinkComponent'
import { Heading } from '@components/Typography'
import Grid from '@components/ViewGridComponent'

import { darkPurpleTheme } from '@src/themes'

type CaseStudyProps = {
  data: {
    allMdx: {
      edges: Array<{
        node: {
          id: string
          frontmatter: {
            slug: string
            linkHeading: string
            linkParagraph: string
            linkImage: string
            linkCaption: string
          }
        }
      }>
    }
    mdx: Page
  }
  path: string
  uri: string
  params: object
  pageResources: any
  navigate: any
  pageContext: {
    id: string
    frontmatter: {
      slug: string
    }
  }
}

type Page = {
  body: string
  frontmatter: {
    slug: string
    heroTitle: string
    heroDescription: string
    heroImage: string
    authorTitle: string
    authorProfile: string
    author: string
    metaTitle: string
    metaDescription: string
    metaImage: string
  }
}

const CaseStudyTemplate: React.FC<CaseStudyProps> = props => {
  const page = props.data.mdx
  const pageMeta = page.frontmatter

  return (
    <>
      <Article>
        <SeoComponent
          title={
            (pageMeta.metaTitle || pageMeta.heroTitle) +
            ' | Big Lemon | App and Web Development Projects with Purpose'
          }
          description={pageMeta.metaDescription || pageMeta.heroDescription}
          image={pageMeta.metaImage || pageMeta.heroImage}
        />
        <FullImageHeroComponent
          image={{ src: pageMeta.heroImage, alt: pageMeta.heroTitle }}
          reduceHeroHeight={false}
          heading={{
            title: pageMeta.heroTitle,
            copy: pageMeta.heroDescription,
          }}
          removePadding
        />
        <ContentComponent body={page.body} />
      </Article>
      <FurtherReadingSection edges={props.data.allMdx.edges} />
    </>
  )
}

const FurtherReadingSection: React.FC<CaseStudyProps['data']['allMdx']> =
  props => {
    const cases = props.edges
      .map(({ node }, index) => {
        return {
          id: node.id,
          heading: node.frontmatter.linkHeading,
          paragraph: node.frontmatter.linkParagraph,
          image: {
            src: node.frontmatter.linkImage,
            alt: node.frontmatter.linkHeading,
            portrait: true,
          },
          to: node.frontmatter.slug,
          caption: node.frontmatter.linkCaption,
        }
      })
      .filter(({ image }) => !!image.src)

    return (
      <ThemeProvider theme={darkPurpleTheme}>
        <SectionComponent>
          <Grid.Row>
            <Grid.Item column='3 / 11'>
              <Spacer bottom='xl3'>
                <QuoteCTA>
                  <Heading centered>Got an idea of your own?</Heading>
                  <Spacer bottom='base' />
                  <SquaredLinkComponent
                    to='/quote'
                    label="Let's get you a quote"
                  />
                </QuoteCTA>
              </Spacer>
            </Grid.Item>
          </Grid.Row>

          <InvertingImageSection data={cases} />
        </SectionComponent>
      </ThemeProvider>
    )
  }

export default CaseStudyTemplate

const Article = styled.article`
  padding-top: ${({ theme }) => theme.spacing.xl2};
`

const QuoteCTA = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const pageQuery = graphql`
  query CaseStudy($id: String!) {
    allMdx(
      filter: {
        frontmatter: { slug: { regex: "/case-study/" } }
        id: { ne: $id }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            linkHeading
            linkParagraph
            linkImage
            linkCaption
          }
        }
      }
    }
    mdx(id: { eq: $id }) {
      slug
      frontmatter {
        slug
        author
        authorProfile
        authorTitle
        heroDescription
        heroImage
        heroTitle
        metaDescription
        metaImage
        metaTitle
        title
      }
      body
    }
  }
`
